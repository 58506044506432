import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@biblioteksentralen/react";
import { ReactNode } from "react";

type ModalContainerProps = {
  heading: string | ReactNode;
  modalContentProps?: ModalContentProps;
  footer?: ReactNode;
  modalFooterProps?: ModalFooterProps;
} & ModalProps;

export const ModalContainer = ({
  heading,
  footer,
  children,
  modalContentProps,
  modalFooterProps,
  ...modalProps
}: ModalContainerProps) => {
  return (
    <Modal isCentered scrollBehavior="inside" {...modalProps}>
      <ModalOverlay />
      <ModalContent
        margin={{ sm: "1rem" }}
        maxWidth={{ sm: "calc(100vh - 2rem)" }}
        maxHeight={{ base: "calc(100vh)", sm: "calc(100vh - 2rem)" }}
        minWidth={{ sm: "22rem" }}
        width={{ base: "100%", sm: "auto" }}
        {...modalContentProps}
      >
        <ModalHeader padding="0 0.5 0.5rem">{heading} </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        {footer && (
          <ModalFooter justifyContent="center" {...modalFooterProps}>
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
