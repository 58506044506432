import { Button, Flex, Text, useDisclosure } from "@biblioteksentralen/react";
import { ReactNode, useEffect } from "react";
import { Info } from "react-feather";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { ModalContainer } from "../../ModalContainer";

type Props = {
  headerText?: string;
  bodyText?: string;
  children?: ReactNode;
  showIcon?: boolean;
};

function InfoModal({ headerText, bodyText, children, showIcon }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();

  useEffect(() => {
    onOpen();
  }, [onOpen]);

  const modalFooter = (
    <Button size="sm" onClick={onClose} width="5rem">
      {t("Lukk")}
    </Button>
  );

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} size="xs" isCentered heading={headerText} footer={modalFooter}>
      {showIcon && (
        <Flex flexDir="column" alignItems="center">
          <Info size="2rem" />
        </Flex>
      )}
      {bodyText && (
        <Flex flexDir="column" alignItems="center">
          <Text paddingTop="1rem">{bodyText}</Text>
        </Flex>
      )}
      {children}
    </ModalContainer>
  );
}

export default InfoModal;
