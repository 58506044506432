import { Button, ButtonGroup, ButtonProps, useDisclosure } from "@biblioteksentralen/react";
import { ReactNode } from "react";
import { ModalContainer } from "../../ModalContainer";

type Props = ButtonProps & {
  bodyText: ReactNode;
  headerText?: ReactNode;
  confirmButtonText: string;
  abortButtonText?: string;
};

function ConfirmModal({ bodyText, headerText, abortButtonText, confirmButtonText, ...chakraProps }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const modalFooter = (
    <ButtonGroup>
      <Button variant="secondary" onClick={onClose}>
        {abortButtonText ?? "Nei"}
      </Button>
      <Button
        variant="primary"
        size="md"
        onClick={(e) => {
          onClose();
          chakraProps.onClick?.(e);
        }}
      >
        {confirmButtonText}
      </Button>
    </ButtonGroup>
  );
  return (
    <>
      <Button {...chakraProps} onClick={onOpen} />
      <ModalContainer
        heading={headerText}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        footer={modalFooter}
        returnFocusOnClose={false}
      >
        {bodyText}
      </ModalContainer>
    </>
  );
}

export default ConfirmModal;
