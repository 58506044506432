import { Link } from "@biblioteksentralen/react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import InternalLink from "../../InternalLink";
import { PRIVACY_POLICY_PATH } from "../../../utils/getPath";
import { FooterMenuList } from "./FooterMenuList";
import { ExternalLink } from "react-feather";

export const SiteFooterMenuItems = ({ accesibilityStatementUrl }: { accesibilityStatementUrl: string | undefined }) => {
  const { t } = useTranslation();

  return (
    <FooterMenuList>
      <li>
        <InternalLink href={PRIVACY_POLICY_PATH} variant="plain">
          {t("Personvernerklæring")}
        </InternalLink>
      </li>
      {accesibilityStatementUrl && (
        <li>
          <Link display="inline-flex" alignItems="center" gap=".3rem" href={accesibilityStatementUrl} variant="plain">
            {t("Tilgjengelighetserklæring")}
            <ExternalLink size="1em" />
          </Link>
        </li>
      )}
      <li>
        <InternalLink href="/nettstedkart" variant="plain">
          {t("Nettstedkart")}
        </InternalLink>
      </li>
    </FooterMenuList>
  );
};
