import { Box, HideWithCSS } from "@biblioteksentralen/react";
import { useSitePalette } from "../../../utils/useSitePalette";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
import { NotLiveBanner } from "./NotLiveBanner";

export const Header = () => {
  const palette = useSitePalette().colors;

  return (
    <Box as="header" {...palette.header.css}>
      <NotLiveBanner />
      <HideWithCSS below="lg">
        <Desktop />
      </HideWithCSS>
      <HideWithCSS above="lg">
        <Mobile />
      </HideWithCSS>
    </Box>
  );
};
